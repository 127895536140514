<template>
  <div class="logout-menu" @click="closeLogoutMenu">
    <div class="user-full-name">
      <v-avatar
        :size="32"
        color="grey lighten-4"
        class="profile_bg"
        :style="{
          backgroundImage: `url(${
            $imageUrl + user.profile_picture || imageAvatar
          })`,
        }"
      ></v-avatar>
      <div class="name-view-profile">
        <div class="username">{{ truncate(user.name, 22) }}</div>
        <div class="view-profile" @click="handleViewProfile">View Profile</div>
      </div>
    </div>
    <div class="list-divider"></div>
    <div class="logout-link" @click="handleLogout">Logout</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { logoutUser } from "@/utils/logoutUser";
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    truncate: {
      type: Function,
      required: true,
    },
    closeLogoutMenu: {
      type: Function,
      required: true,
    },
  },
  components: {},

  data: () => ({}),

  created() {},
  methods: {
    handleViewProfile(e) {
      e.preventDefault();
      if (this.currentUser.user_type == 1) {
        this.$router.push({ name: "dev_profile" });
      } else {
        let id = this.$route.params.id;
        this.$router.push({ name: "client_profile", params: { id } });
      }
    },
    handleLogout() {
      logoutUser();
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.logout-menu {
  position: absolute;
  background: #ffffff;
  box-shadow: 5px 7px 30px rgba(21, 23, 26, 0.05);
  border-radius: 4px;
  width: 176px;
  padding: 16px 8px;
  text-align: left;
  bottom: 61px;
  left: 0px;
}
.user-full-name {
  padding-left: 8px;
  display: flex;
}
.list-divider {
  border-top: 1px solid #e4e5e7;
  margin-top: 16px;
}
.logout-link {
  margin-top: 16px;
  padding-left: 8px;
  font-size: 12px;
  line-height: 14px;
  color: #53585f;
  cursor: pointer;
}
.name-view-profile {
  margin-left: 8px;
}
.username {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.view-profile {
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  color: #0781f2;
  margin-top: 4px;
}
</style>
