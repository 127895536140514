var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logout-menu", on: { click: _vm.closeLogoutMenu } },
    [
      _c(
        "div",
        { staticClass: "user-full-name" },
        [
          _c("v-avatar", {
            staticClass: "profile_bg",
            style: {
              backgroundImage:
                "url(" +
                (_vm.$imageUrl + _vm.user.profile_picture || _vm.imageAvatar) +
                ")",
            },
            attrs: { size: 32, color: "grey lighten-4" },
          }),
          _c("div", { staticClass: "name-view-profile" }, [
            _c("div", { staticClass: "username" }, [
              _vm._v(_vm._s(_vm.truncate(_vm.user.name, 22))),
            ]),
            _c(
              "div",
              {
                staticClass: "view-profile",
                on: { click: _vm.handleViewProfile },
              },
              [_vm._v("View Profile")]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "list-divider" }),
      _c(
        "div",
        { staticClass: "logout-link", on: { click: _vm.handleLogout } },
        [_vm._v("Logout")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }